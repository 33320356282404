function About(){
    

    return (
        <>
            <h1 className="text-center">
                <span className="px-3" style={{borderBlockEnd: "5px solid yellow"}}>
                    About ChrezBot
                </span>
            </h1>

            <p>
                
            </p>            

        </>
    )
}

export default About;