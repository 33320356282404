function Events(){
    

    return (
        <>
            <h1 className="text-center">
                <span className="px-3" style={{borderBlockEnd: "5px solid yellow"}}>
                    Events
                </span>
            </h1>

            

        </>
    )
}

export default Events;